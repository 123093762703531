.legend__container {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
  background-color: rgba(124, 46, 254, 0.3);
  border-radius: 20px;
}

.legend__content {
  margin-bottom: 16px;
}
.legend__author {
  font-style: italic;
}
